export default [
  {
    path: "/restoration",
    name: "restoration",
    component: () => import("@/apps/restoration/views/Restoration"),
    meta: {
      title: "Восстановление пароля",
    },
  },
];
