export default [
  {
    path: "/sms",
    name: "sms",
    component: () => import("@/apps/sms/views/Sms"),
    meta: {
      title: "Профиль",
    },
  },
];
