import { createRouter, createWebHistory } from "vue-router";
import routes from "./routes";
import { getTitle } from "../utils/getTitle";

const router = createRouter({
  history: createWebHistory(),
  routes,
});

router.beforeEach((to, from, next) => {
  getTitle(to);
  next();
});

export default router;
