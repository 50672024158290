import { createStore } from "vuex";
import core$browser from "./browser";
import core$main from "./main";

const store = createStore({
  modules: {
    core$browser,
    core$main,
  },
});

export default store;
