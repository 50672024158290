export default [
  {
    path: "/profile",
    name: "profile",
    component: () => import("@/apps/profile/views/Profile"),
    meta: {
      title: "Профиль",
    },
  },
];
