const state = {
  size: 0,
};

const getters = {
  size: (state) => state.size,
  deviceIs: (state) => {
    if (state.size < 767) return "mobile";
    if (state.size < 1200) return "tablet";
    return "desktop";
  },
};

const actions = {};

const mutations = {
  setWindowSize: (state) => {
    state.size = document.documentElement.clientWidth;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
