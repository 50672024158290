import "@/core/styles/main.scss";

import VueNumberInput from "@chenfengyuan/vue-number-input";
import { createApp } from "vue";
// import * as login from "@/apps/login";
import App from "./App.vue";
import router from "./router";
import store from "./stores";

(async () => {
  const app = createApp(App);
  app.component(VueNumberInput.name, VueNumberInput);

  // await login.connect();

  app.use(router);
  app.use(store);

  app.mount("#app");
})();
