export default [
  {
    path: "/registration",
    name: "registration",
    component: () => import("@/apps/registration/views/Registration"),
    meta: {
      title: "Регистрация",
    },
  },
];
