export default [
  {
    path: "/login",
    name: "login",
    component: () => import("@/apps/login/views/Login.vue"),
    meta: {
      title: "Авторизация",
    },
  },
];
