export default [
  {
    path: "/loginbytoken",
    name: "loginToken",
    component: () => import("@/apps/login-token/views/LoginToken"),
    meta: {
      title: "Вход по токену",
    },
  },
];
